'use strict';

/**
 * @param {string} cookieName oneTrust ID without -test
 * @returns {string} the oneTrust ID used for OtAutoBlock.js
 */
function getCookie(cookieName) {
	let cookie = {};
	document.cookie.split(';').forEach(function (el) {
		let [key, value] = el.split('=');
		cookie[key.trim()] = value;
	});

	return cookie[cookieName];
}

/**
 * Initializes the cookie banner based on the user's cookie acceptance status.
 * If cookies are not accepted, the cookie banner and functional note are displayed.
 * If cookies are accepted, the cookie banner and functional note are hidden.
 */
function init() {
	const cookies = getCookie('cookiesAccepted');

	if (cookies === 'false') {
		$('#cookie-banner').removeClass('d-none');
		$('.cookie-functional-note').removeClass('d-none');
	} else {
		$('#cookie-banner').addClass('d-none');
		$('.cookie-functional-note').addClass('d-none');
	}
}

document.addEventListener('DOMContentLoaded', init);

/**
 * Set Consent for Cookies
 */
function allowCookies() {
	$('body').on('click', '#onetrust-accept-btn-handler, #accept-recommended-btn-handler', function () {
		document.cookie = 'cookiesAccepted=true';
		$('#cookie-banner').addClass('d-none');
		$('.cookie-functional-note').addClass('d-none');
	});
}

/**
 * Set Consent for Cookies
 */
function denyCookies() {
	$('body').on('click', '#onetrust-reject-all-handler', function () {
		document.cookie = 'cookiesAccepted=false';
		$('#cookie-banner').removeClass('d-none');
		$('.cookie-functional-note').removeClass('d-none');
	});
}

/**
 * Check if the cookie needed for maps/youtube have been accepted
 */
function functionalCookieAccept() {
	const $cookieBannerYoutube = $('.cookie-banner-youtube');
	const $cookieBannerMaps = $('.cookie-banner-maps');
	const $videoListItem = $('.videoListItem');
	const $mapsItems = $('.jumbotron.map-canvas');
	const $thumbnail = $('.thumbnail');

	$('body').on('change', '#ot-group-id-C0003', function () {
		let isChecked = $(this).prop('checked');
		document.cookie = 'cookiesAccepted=' + isChecked;
	});

	$('body').on('click', '.save-preference-btn-handler', function () {
		const cookiesAccepted = getCookie('cookiesAccepted');

		if (cookiesAccepted === 'true') {
			$cookieBannerYoutube.addClass('d-none');
			$cookieBannerMaps.addClass('d-none');
			$videoListItem.removeClass('thumbnail-without-cookies');
			$mapsItems.removeClass('thumbnail-without-cookies');
			$thumbnail.removeClass('thumbnail-without-cookies');
		} else {
			$cookieBannerYoutube.removeClass('d-none');
			$cookieBannerMaps.removeClass('d-none');
			$videoListItem.addClass('thumbnail-without-cookies');
			$mapsItems.addClass('thumbnail-without-cookies');
			$thumbnail.addClass('thumbnail-without-cookies');
		}
	});
}

module.exports = {
	init: init,
	getCookie: getCookie,
	functionalCookieAccept: functionalCookieAccept,
	allowCookies: allowCookies,
	denyCookies: denyCookies
};
